@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes modal-video {
  from {
    opacity: 0
  }

  to {
    opacity: 1
  }
}

@keyframes modal-video-inner {
  from {
    transform: translate(0, 100px)
  }

  to {
    transform: translate(0, 0)
  }
}

.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  cursor: pointer;
  opacity: 1;
  animation-timing-function: ease-out;
  animation-duration: .3s;
  animation-name: modal-video;
  -webkit-transition: opacity .3s ease-out;
  -moz-transition: opacity .3s ease-out;
  -ms-transition: opacity .3s ease-out;
  -o-transition: opacity .3s ease-out;
  transition: opacity .3s ease-out
}

.modal-video-effect-exit {
  opacity: 0
}

.modal-video-effect-exit .modal-video-movie-wrap {
  -webkit-transform: translate(0, 100px);
  -moz-transform: translate(0, 100px);
  -ms-transform: translate(0, 100px);
  -o-transform: translate(0, 100px);
  transform: translate(0, 100px)
}

.modal-video-body {
  max-width: 940px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: table
}

.modal-video-inner {
  display: table-cell;
  vertical-align: middle;
  width: 100%;
  height: 100%
}

.modal-video-movie-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  background-color: #333;
  animation-timing-function: ease-out;
  animation-duration: .3s;
  animation-name: modal-video-inner;
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transition: -webkit-transform .3s ease-out;
  -moz-transition: -moz-transform .3s ease-out;
  -ms-transition: -ms-transform .3s ease-out;
  -o-transition: -o-transform .3s ease-out;
  transition: transform .3s ease-out
}

.modal-video-movie-wrap iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%
}

.modal-video-close-btn {
  position: absolute;
  z-index: 2;
  top: -35px;
  right: -35px;
  display: inline-block;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border: none;
  background: transparent
}

.modal-video-close-btn:before {
  transform: rotate(45deg)
}

.modal-video-close-btn:after {
  transform: rotate(-45deg)
}

.modal-video-close-btn:before,
.modal-video-close-btn:after {
  content: '';
  position: absolute;
  height: 2px;
  width: 100%;
  top: 50%;
  left: 0;
  margin-top: -1px;
  background: #fff;
  border-radius: 5px;
  margin-top: -6px
}




@import url("//hello.myfonts.net/count/3c72a3");

@font-face {
  font-family: 'Gilroy-Light';
  src: url('/fonts/Gilroy-Light/font.woff2') format('woff2'), url('/fonts/Gilroy-Light/font.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy-Regular';
  src: url('/fonts/Gilroy-Regular/font.woff2') format('woff2'), url('/fonts/Gilroy-Regular/font.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy-Bold';
  src: url('/fonts/Gilroy-Bold/font.woff2') format('woff2'), url('/fonts/Gilroy-Bold/font.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy-SemiBold';
  src: url('/fonts/Gilroy-SemiBold/font.woff2') format('woff2'), url('/fonts/Gilroy-SemiBold/font.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy-Medium';
  src: url('/fonts/Gilroy-Medium/font.woff2') format('woff2'), url('/fonts/Gilroy-Medium/font.woff') format('woff');
  font-display: swap;
}

@layer base {
  h1 {
    @apply text-7xl;
  }
}

#__next {
  overflow: auto;
}

#__next>.grid>aside {
  background-image: url('/footer_bg.png')
}

.tagline:before {
  content: "";
  display: block;
  height: 1px;
  width: 32px;
  top: 46%;
  position: absolute;
  left: 0;
  background-color: currentColor;
}

@screen lg {
  .tagline:before {
    content: "";
    display: block;
    height: 2px;
    width: 32px;
    top: 48%;
    position: absolute;
    left: 0;
    background-color: currentColor;
  }
}

@screen xl {
  .tagline:before {
    content: "";
    display: block;
    height: 2px;
    width: 34px;
    top: 48%;
    position: absolute;
    left: 0;
    background-color: currentColor;
  }
}

.tagline-white:before {
  content: "";
  display: block;
  height: 2px;
  width: 48px;
  top: 48%;
  position: absolute;
  left: 0;
  background-color: white;
}

.tagline-lg:before {
  content: "";
  display: block;
  height: 1px;
  width: 40px;
  top: 46%;
  position: absolute;
  left: 0;
  background-color: #212747;
}

@screen lg {
  .tagline-lg:before {
    content: "";
    display: block;
    height: 2px;
    width: 80px;
    top: 48%;
    position: absolute;
    left: 0;
    background-color: #212747;
  }
}


.carousel-open:checked+.carousel-item {
  position: static;
  opacity: 100;
}

.carousel-item {
  -webkit-transition: opacity 0.6s ease-out;
  transition: opacity 0.6s ease-out;
}

#carousel-1:checked~.control-1,
#carousel-2:checked~.control-2,
#carousel-3:checked~.control-3 {
  display: block;
}

.carousel-indicators {
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  bottom: 2%;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 10;
}

#carousel-1:checked~.control-1~.carousel-indicators li:nth-child(1) .carousel-bullet,
#carousel-2:checked~.control-2~.carousel-indicators li:nth-child(2) .carousel-bullet,
#carousel-3:checked~.control-3~.carousel-indicators li:nth-child(3) .carousel-bullet {
  color: #2b6cb0;
  /*Set to match the Tailwind colour you want the active one to be */
}

.downloads-masonry>div:before {
  content: "";
  padding-bottom: 100%;
  display: block;
}

.gesichte:after {
  content: "";
  padding-bottom: 33%;
  display: block;
}

.rectangle:before {
  content: "";
  padding-bottom: 50%;
  display: block;
}

.historic-reports {
  direction: ltr;
}

.historic-reports:before {
  content: none !important;
}

@screen lg {
  .historic-report:before {
    content: none !important;
  }
}

.markdown-box:before {
  content: '';
  width: 0;
  padding-bottom: 100%;
  grid-row: 1 / 1;
  grid-column: 1 / 1;
}

@responsive {
  .masonry {
    transition: all .5s ease-in-out;
    column-gap: 0;
    column-fill: initial;
  }
}

@screen md {
  .masonry {
    column-count: 2;
  }
}

@screen lg {
  .masonry {
    column-count: 3;
  }
}

.square-img:before {
  content: '';
  display: block;
  padding-bottom: 100%;
}

@screen xl {
  .square:before {
    content: '';
    display: block;
    padding-bottom: 100%;
  }
}

.sm-square:after {
  content: '';
  display: block;
  padding-bottom: 75%;
}

@screen lg {
  .sm-square:after {
    content: none;
  }
}

.gray-section:after {
  content: '';
  @apply absolute;
  @apply bg-gray-100;
  @apply block;
  @apply top-0;
  z-index: -1;
  left: -25%;
  right: -25%;
  height: 135%;
}

.gray-section2:after {
  content: '';
  @apply bg-gray-100;
  @apply block;
  @apply h-full;
  @apply right-0;
  @apply top-0;
  z-index: -1;
  left: -10%;
}

@screen lg {
  .gray-section .grid>article {
    margin-left: 4rem;
  }
}

.kontakt {
  background-color: #f7f7f7;
}

.downloadable-item>a:after {
  @apply absolute;
  @apply bg-center;
  @apply bg-light-green;
  @apply bg-no-repeat;
  @apply block;
  @apply bottom-0;
  @apply duration-500;
  @apply ease-in-out;
  @apply left-0;
  @apply opacity-0;
  @apply right-0;
  @apply top-0;
  @apply transition;
  content: "";
  background-image: url('/icons/Icon_Download.svg');
  background-size: 3rem;
  background-position: center center;
}

.downloadable-item a:hover:after {
  @apply opacity-100;
}

@screen lg {
  .links-grid>a:first-child {
    @apply col-span-5
  }

  .links-grid>a:nth-child(2) {
    @apply col-span-3
  }
}

.video-player-wrapper {
  @apply relative;
  padding-top: 56.25%;
  /* Player ratio: 100 / (1280 / 720) */
}

@screen xl {
  .video-border {
    border: 3rem solid #efefef;
  }
}

.video-player {
  margin-left: 0;
}

@screen xl {
  .video-player {
    margin-left: -202px;
  }
}


.react-player {
  @apply absolute;
  @apply top-0;
  @apply left-0;
}

/* .player-wrapper {
  width: auto;
  height: auto;
}
.react-player {
  padding-top: 56.25%;
  position: relative;
}

.react-player > div {
  position: absolute;
} */

.dropdown-menu {
  top: 105%;
}

.dropdown-menu>div>a:last-child {
  border-bottom: none;
}

.is-active {
  border-bottom: 2px solid #97BDE4;
}

.google-map {
  background-image: url('/Maps_Demo2.png');
}

.google-map>div {
  @apply h-full;
}

.block-teaser>div:last-child {
  @apply text-gray-900;
}

.block-teaser:hover>div:last-child {
  color: inherit;
}

.accordion-main {
  counter-reset: accordion-count;
}

.accordion-main-counter {
  counter-increment: accordion-count;
}

.accordion-main-counter:before {
  content: counter(accordion-count, decimal-leading-zero);
  display: block;
  position: absolute;
  left: 0;
}

/* @screen xl {
.heading-bg-video {
  width: 394px;
  height: 472px;
}
} */

@screen lg {
  .container-wrapper {
    @apply bg-gray-100;
    max-width: 80rem;
  }
}

@media (min-width: 120rem) {
  .container-wrapper {
    max-width: 120rem;
    width: 120rem;
  }

  /* .heading-bg-image {
    @apply absolute;
    width: 75.75rem !important;
    height: 33.75rem;
  }
  .heading-bg-video {
    @apply block;
    height: 49rem;
    width: 41rem;
  } */
  /* .heading-text {
    margin-top: 36rem !important;
  } */
  .gray-section3:before {
    content: '';
    position: absolute;
    @apply bg-gray-100;
    @apply block;
    @apply h-full;
    @apply top-0;
    @apply bottom-0;
    width: 90rem;
    margin: 0 auto;
    z-index: -1;
    left: 12rem;
  }
}

.paging-item {
  padding: 4px;
  border: 3px solid red;
}

@screen lg {
  .heading-huge {
    font-size: 13rem;
    line-height: 0.85;
  }
}

.vertical-slider {
  animation: fadeIn 700ms ease-in-out forwards;
  transition: background-image 700ms ease-in;
}

.vertical-slider:after {
  @apply absolute;
  @apply block;
  @apply bg-black;
  @apply bg-opacity-50;
  content: '';
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: -1;
}

.vertical-slide {
  animation: fadeIn 500ms ease-in-out forwards;
  transition: background-image 500ms ease-in-out;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.chart {
  min-height: 32rem;
  margin-left: -1rem;
  margin-right: -1rem;
}

@screen md {
  .chart {
    min-height: 32rem;
    margin-left: 0;
    margin-right: 0;
  }
}

.chart-pie {
  height: 54rem;
}

@screen xl {
  .chart-pie {
    height: 32rem;
  }
}


.flip {
  height: 20rem;
}

.flip-inner {
  width: 100%;
  height: 100%;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.flip:hover .flip-inner {
  transform: rotateY(180deg);
}

/* Position the front and back side */
.flip-front,
.flip-back {
  -webkit-backface-visibility: hidden;
  /* Safari */
  backface-visibility: hidden;
}

/* Style the front side (fallback if image is missing) */
.flip-front {
  background-color: #bbb;
  color: black;
}

/* Style the back side */
.flip-back {
  transform: rotateY(180deg);
}

.text-shadow {
  text-shadow: 0px 0px 13px rgba(0, 0, 0, 1);
}

.contact-form input::placeholder {
  @apply text-dark-grey;
  opacity: 1;
}

.contact-form-new input, .contact-form-new textarea {
  width: 100%
}

.contact-form-new input::placeholder, .contact-form-new textarea::placeholder {
  @apply text-black text-sm leading-none;
  opacity: 1;
}

.horizontal-heading h2 {
  @apply leading-none;
  @apply pt-2;
  display: inline-block;
  padding-right: 1rem;
}

.block-teaser:hover .icon-wrapper {
  color: inherit !important;
}

.square2 {
  width: 100%;
  height: 0;
  padding-top: 100%;
  position: relative;
}

.square2>div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.our-mission {
  @apply border-light-olive-green;
  @apply border-solid;
  @apply outline-none;
  border-width: 30px;
}

@screen lg {
  .our-mission {
    border-width: 50px;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fade-in {
  animation: fade-in 1s;
}

.expandable-menu .dropdown-menu {
  transform: scale(0.75, 0.5625);
}

@screen lg {
  .expandable-menu:hover .dropdown-menu {
    visibility: visible;
    opacity: 1;
    transform: none;
    transition: opacity 251ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 167ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
}

.expandable-menu .arrow-down {
  transform: none;
  transition: transform 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

@screen lg {
  .expandable-menu:hover .arrow-down {
    transform: rotate(180deg);
  }

  .leitbild:after {
    display: block;
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: -156px;
    right: -32px;
    bottom: 0;
    background-color: #f7f7f7;
  }
}

.article h2 {
  @apply text-3xl;
  @apply font-semiBold;
}

.article p {
  @apply text-lg;
  padding-bottom: 1.5rem;
}

.article a {
  @apply font-regular;
  @apply underline;
}

.article br {
  @apply text-xl;
}

.gallery-images>button>div {
  @apply h-44;
}

.gallery-images>div {
  @apply h-44;
}

.modal-video {
  height: 100vh !important;
}

.square-video:before {
  content: '';
  display: block;
  padding-bottom: 120%;
}

.custom-scrollbar::-webkit-scrollbar {
  height: 5px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background-color: #212747;
  border-radius: 5px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #99B7CD;
  border-radius: 5px;
}

@screen lg {
  .custom-scrollbar::-webkit-scrollbar {
    height: 8px;
  }
}

.is-active {
  border-bottom: none;
}

@screen lg {
  .splide__pagination {
    display: none !important
  }
}
